var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-view',[_c('view-section',[_c('h1',{staticClass:"mb-8 text-4xl font-semibold"},[_vm._v("Search Results")]),(!_vm.articles)?_c('div',[_vm._v("Loading...")]):_c('div',[_c('div',{staticClass:"overflow-hidden rounded-lg shadow-lg"},[_c('p',{staticClass:"px-4 py-2 m-0 text-white bg-primary"},[_vm._v(" Showing results "+_vm._s(10 * (_vm.currentPage - 1) + 1)+" - "+_vm._s(_vm.currentPage === _vm.numPages ? _vm.results.length : 10 * _vm.currentPage)+" of "+_vm._s(_vm.results.length)+" for \""),_c('i',[_vm._v(_vm._s(_vm.query))]),_vm._v(" \" ")]),_vm._l((_vm.slicedResults),function(result,index){return _c('router-link',{key:index,staticClass:"block p-4 border-0 border-b border-neutral border-solid last:border-b-0 hover:bg-neutral-light active:bg-neutral",attrs:{"to":'/support/articles/' + result.item.id}},[_vm._v(" "+_vm._s(result.item.title)+" ")])}),_c('div',{staticClass:"p-2 text-center text-white bg-neutral-900"},[_vm._l((_vm.numPages),function(page){return [(
                page === 1 ||
                  page === _vm.numPages ||
                  (page >= _vm.currentPage - 2 && page <= _vm.currentPage + 2)
              )?_c('span',{key:page,staticClass:"inline-block mx-2 my-0 cursor-pointer select-none hover:text-primary-light",class:{ 'text-primary-light': _vm.currentPage === page },on:{"click":function($event){_vm.currentPage = page}}},[_vm._v(" "+_vm._s(page)+" ")]):(
                (_vm.currentPage - 3 > 1 && page === 2) ||
                  (_vm.currentPage + 3 < _vm.numPages && page === _vm.numPages - 1)
              )?_c('span',{key:page,staticClass:"mx-2 my-0 select-none"},[_vm._v("...")]):_vm._e()]})],2)],2)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }